<template>
  <div class="index">
    <div class="nav1">
      <div class="tit1">用户管理</div>
    </div>
    <div class="nav2">
      <div class="tit1">
        <el-button @click="AddSaishi" size="small" type="primary" icon="el-icon-plus">添加用户</el-button>
        <el-tag>日活:{{todayActiveCount}}</el-tag>
        <el-tag>月活:{{monthActiveCount}}</el-tag>
        
        <!-- 添加搜索框 -->
        <el-input
          v-model="searchUserName"
          placeholder="请输入账号搜索"
          style="width: 250px; margin-left: 10px"
          size="small"
          clearable
          @clear="handleSearch"
          @input="handleSearch">
          <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
        </el-input>
      </div>
      <div class="myTable">
        <vxe-table :loading="loading" align="center" :data="currentPageData">
          <vxe-table-column field="userName" width="120" title="账号"></vxe-table-column>
          <vxe-table-column field="shareNameFrom" width="120" title="推荐人"></vxe-table-column>
          <vxe-table-column field="createTime" width="150" title="创建时间"></vxe-table-column>
          <vxe-table-column field="expireDate" width="100" title="到期日期"></vxe-table-column>
          <!-- <vxe-table-column field="searchMax" title="限额"></vxe-table-column> -->
          <!-- <vxe-table-column field="searchCount" title="累计次数"></vxe-table-column> -->
          <vxe-table-column field="leftCount" title="剩余额度"></vxe-table-column>
          <vxe-table-column field="onlinePostCount" title="在招数量"></vxe-table-column>
          <vxe-table-column field="status" title="启用状态">
            <template slot-scope="scope">
              <el-switch
                  @change="changeUserStatus($event,scope.row)"
                  :value="scope.row.status==0"
                  active-text=""
                  inactive-text=""
              ></el-switch>
            </template>
          </vxe-table-column>
          <vxe-table-column field="pv1" title="今日登录"></vxe-table-column>
          <vxe-table-column field="pv7" title="7日登录"></vxe-table-column>
          <vxe-table-column field="pv30" title="30日登录"></vxe-table-column>
          <vxe-table-column field="resumedbShow" title="显示简历库">
            <template slot-scope="scope">
              <el-switch
                  @change="changeUserResumedbShow($event,scope.row)"
                  :value="scope.row.resumedbShow==1"
                  active-text=""
                  inactive-text=""
              ></el-switch>
            </template>
          </vxe-table-column>
<!--          <vxe-table-column field="nickName" title="姓名"></vxe-table-column>-->
<!--           <vxe-table-column field="email" title="邮箱"></vxe-table-column>-->
<!--           <vxe-table-column field="phonenumber" title="手机"></vxe-table-column>-->

          <vxe-table-column title="操作" width="180">
            <template slot-scope="scope">
              <div class="flex">
                <el-button size="small" type="text" @click="toEdit(scope.row)">修改</el-button>
                <el-button size="small" type="text" @click="toResetPass(scope.row)">重置密码</el-button>
              </div>
            </template>
          </vxe-table-column>
          <vxe-table-column field="tips" title="安全提示"></vxe-table-column>
        </vxe-table>
        <el-pagination 
          class="fenye" 
          @size-change="handleSizeChange" 
          @current-change="handleCurrentChange"
          :current-page="currentPage" 
          :page-size="pageSize" 
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper" 
          :total="filteredTotal">
        </el-pagination>
      </div>
    </div>
    <!-- 添加/修改广告 -->
    <el-dialog title="添加/修改用户" :visible.sync="addDialogVisible" width="700px" :before-close="addHandleClose">
      <div class="myAddForm">
        <el-form :model="addForm" :rules="rules" ref="addForm" label-width="100px" class="demo-addForm">
          <el-row>
            <el-col :span="20">
              <el-form-item label="登录账号" prop="userName">
                <el-input size="small" placeholder="请输入登录账号,可以是手机号或邮箱或自定义" v-model="addForm.userName" :disabled="!isAdd"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="密码" prop="password" v-if="isAdd">
                <el-input size="small" placeholder="请输入密码" v-model="addForm.password"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="公司" prop="company">
                <el-input size="small" placeholder="请输入公司" v-model="addForm.company"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="姓名" prop="nickName">
                <el-input size="small" placeholder="请输入姓名" v-model="addForm.nickName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="职务" prop="job">
                <el-input size="small" placeholder="请输入职务" v-model="addForm.job"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="到期日期" prop="expireDate">
                <el-date-picker
                    v-model="addForm.expireDate"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择到期日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="限额" prop="searchMax">
                <el-input-number v-model="addForm.searchMax" :step=100 :min=0 :max=1000000></el-input-number>
<!--                <el-input size="small" placeholder="请输入限额" v-model="addForm.searchMax"></el-input>-->
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="累计次数" prop="searchCount">
                <el-input disabled size="small" placeholder="" v-model="addForm.searchCount"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="邮箱" prop="email">
                <el-input size="small" placeholder="请输入邮箱" v-model="addForm.email"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="手机" prop="mobile">
                <el-input size="small" placeholder="请输入手机" v-model="addForm.phoneNumber"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item>
                <el-button size="small" type="primary" @click="AddOnSubmit('addForm')">提交</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-dialog>
    <input type="file" name="companyLogo" id="file0" class="displayN" multiple="multiple" @change="companyLogo($event)"
           ref="fileInputList" />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState([
      "tabShopIndex",
      "biaobaiqiangPage",
      "biaobaiqiangPageSize",
      "xiaoshoubiaoPage",
      "xiaoshoubiaoPageSize"
    ]),
    currentPageData() {
      // 先过滤数据
      const filteredData = this.tableData.filter(item => {
        if (!this.searchUserName) return true;
        return item.userName.toLowerCase().includes(this.searchUserName.toLowerCase());
      });
      
      // 再分页
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return filteredData.slice(start, end);
    },
    filteredTotal() {
      return this.tableData.filter(item => {
        if (!this.searchUserName) return true;
        return item.userName.toLowerCase().includes(this.searchUserName.toLowerCase());
      }).length;
    }
  },
  watch: {
    tabShopIndex: function () {
      this.activeName = this.tabShopIndex;
      console.log(this.activeName);
      this.getData();
    },
    biaobaiqiangPage: function (page) {
      this.$store.commit("biaobaiqiangPage", page);
      this.getData();
    },
    biaobaiqiangPageSize: function (pageSize) {
      this.$store.commit("biaobaiqiangPageSize", pageSize);
      this.getData();
    },
    xiaoshoubiaoPage: function (page) {
      this.$store.commit("xiaoshoubiaoPage", page);
      this.getxsData();
    },
    xiaoshoubiaoPageSize: function (pageSize) {
      this.$store.commit("xiaoshoubiaoPageSize", pageSize);
      this.getxsData();
    }
  },
  data() {
    return {
      is_status: -1,
      loading: false,
      activeName: "1",
      searchForm: {
        challenges_id: "",
      },
      tableData: [],
      total: 0,
      todayActiveCount:0,
      monthActiveCount:0,
      addDialogVisible: false,
      addForm: {
        userId:null,
        userName:"",
        nickName:"",
        company:"",
        job:"",
        email:"",
        expireDate:"",
        searchMax:100,
        searchCount:0,
        resumedbShow:1,
        resumedbShowSwitch:true,
        phoneNumber:"",
        password:"Hirebot",
        status:0
      },
      rules: {
        userName: [{ required: true, message: "请输入登录账号", trigger: "blur" }],
        nickName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        company: [{ required: true, message: "请输入公司", trigger: "blur" }],
        job: [{ required: true, message: "请输入职务", trigger: "blur" }],
        searchMax: [{ required: true, message: "请输入限额", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      },
      imgStatus: "",
      imgFile: "",
      id: "",
      xsaddDialogVisible: false,
      xstableData: [],
      xssearchForm: {
        time: ""
      },
      xstotal: 0,
      SFlist: [],
      CSlist: [],
      imgIndex: 0,
      Userlist: [],
      isAdd: false,
      SSlist: [],
      currentPage: 1,
      pageSize: 10,
      searchUserName: '', // 添加搜索关键字
    };
  },
  created() {
    this.$store.commit("biaobaiqiangPage", 1);
    this.$store.commit("biaobaiqiangPageSize", 10);
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      const res = await this.$api.hirebotuser_list();
      console.log(res);
      this.tableData = res.data ? res.data : [];
      this.total = res.total
      this.loading = false;
      this.todayActiveCount=res.todayActiveCount
      this.monthActiveCount=res.monthActiveCount
    },
    async changeUserStatus(event,row){
      this.addForm = { ...row }
      this.addForm.status=row.status==1?0:1
      const res = await this.$api.hirebotuser_update({
        ...this.addForm
      }).then(()=>{
        row.status=this.addForm.status
      })
      console.log(res)
    },
    async changeUserResumedbShow(event,row){
      this.addForm = { ...row }
      this.addForm.resumedbShow=row.resumedbShow==1?0:1
      const res = await this.$api.hirebotuser_update({
        ...this.addForm
      }).then(()=>{
        row.resumedbShow=this.addForm.resumedbShow
      })
      console.log(res)
    },
    changeSF() {
      this.getCSData()
    },
    xssearchOnSubmit() {
      this.getxsData();
    },
    addEditGuanliyuan(row) {
      this.id = row.id;
      this.addForm.user_name = row.store_username;
      this.addForm.status = row.store_userstatus.toString();
      this.addDialogVisible = true;
    },
    seeXiaoshou(row) {
      this.id = row.id;
      this.xsaddDialogVisible = true;
    },
    xsaddHandleClose() {
      this.xsaddDialogVisible = false;
    },
    // 开关（显示/隐藏）
    async changeKG2(row) {
      console.log(row);
      const res = await this.$api.store_setflag({
        id: row.id,
        flag: row.myFlagship == true ? "1" : "0"
      });
      console.log(res);
      if (res.data.result == 1) {
        this.$message({
          message: res.data.msg,
          type: "success"
        });
        this.getData();
      }
    },
    async changeKG(row) {
      console.log(row);
      const res = await this.$api.store_setstatus({
        id: row.id,
        status: row.myIs_status == true ? "1" : "0"
      });
      if (res.data.result == 1) {
        this.$message({
          message: res.data.msg,
          type: "success"
        });
        this.addDialogVisible = false;
        this.getData();
      }
    },
    toEdit(row) {
      // this.addForm = { ...row }
      this.addForm.userId=row.userId
      this.addForm.userName=row.userName
      this.addForm.nickName=row.nickName
      this.addForm.email=row.email
      this.addForm.phoneNumber=row.phonenumber
      this.addForm.status=row.status

      this.addForm.company=row.company
      this.addForm.job=row.job
      this.addForm.expireDate=row.expireDate
      this.addForm.searchMax=row.searchMax
      this.addForm.searchCount=row.searchCount
      this.addForm.resumedbShow=row.resumedbShow

      this.isAdd = false
      this.addDialogVisible = true
    },
    toResetPass(row){
      this.$prompt('请输入密码', '重置密码', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /.{6,}/,
        inputErrorMessage: '密码至少6位'
      }).then(({ value }) => {
        this.$api.hirebotuser_resetpass({
          userName:row.userName,
          newPassword:value
        }).then(()=>{
          this.$message({
            type: 'success',
            message: '修改成功'
          });
        })
      }).catch(() => {

      });
    },
    async toDel(row) {
      const res = await this.$api.del_swiper({
        id: row.id
      })
      if (res.result == 1) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getData();
      }
    },
    tabsHandleClick(tab) {
      console.log(tab.index);
      this.$store.commit("tabShopIndex", (Number(tab.index) + 1).toString());
    },
    searchOnSubmit() {
      console.log(this.searchForm);
    },
    AddSaishi() {
      this.isAdd = true;
      for (const key in this.addForm) {
        if (key != 'password') {
          this.$set(this.addForm, key, "");
        }
      }
      this.addForm.password="Hirebot2024"
      this.addForm.searchCount=0
      this.addForm.searchMax=100
      this.addDialogVisible = true;
    },
    addHandleClose() {
      this.addDialogVisible = false;
    },
    tabEdit(row) {
      console.log(row);
      this.addForm = { ...row };
      this.addForm.phoneNumber=row.phonenumber
      this.addDialogVisible = true;
    },
    async tabDel(row) {
      console.log(row);
      const res = await this.$api.categoryDel(row.id);
      if (res) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        setTimeout(() => {
          this.getData();
        }, 500);
      } else {
        this.$message.error(res.msg);
      }
    },
    resumedbShowChange(row){
      this.addForm.resumedbShow=row
    },
    AddOnSubmit(formName) {
      console.log(this.addForm);
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (this.isAdd) {
            // 新增
            const res = await this.$api.hirebotuser_add({
              ...this.addForm,
            });
            if (res.result == 1) {
              this.$message({
                message: res.msg,
                type: "success"
              });
              this.addDialogVisible = false;
              this.getData();
            } else {
              this.$message({
                message: res.msg,
              });
            }
          } else {
            // 修改
            const res = await this.$api.hirebotuser_update({
              ...this.addForm
            });
            if (res.result == 1) {
              this.$message({
                message: res.msg,
                type: "success"
              });
              this.addDialogVisible = false;
              this.getData();
            }else {
              this.$message({
                message: res.msg,
              });
            }
          }

        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 删除图片
    delImg(val, i = 0) {
      if (val == "details_pic") {
        if (this.addForm.details_pic[i].name != '') {
          this.addForm.details_pic.splice(i, 1)
        }
      } else if (val == "tb") {
        this.$set(this.addForm,'pic','')
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    xshandleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.$store.commit("xiaoshoubiaoPageSize", val);
    },
    xshandleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.$store.commit("xiaoshoubiaoPage", val);
    },
    // 上传图片
    companyList(val, i = 0) {
      this.imgIndex = i;
      this.imgStatus = val;
      this.$refs.fileInputList.click();
    },
    //将文件转为blob类型
    readFileAsBuffer(file) {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.readAsDataURL(file);
        reader.onload = function () {
          const base64File = reader.result.replace(
              /^data:\w+\/\w+;base64,/,
              ""
          );
          resolve(new window.OSS.Buffer(base64File, "base64"));
        };
      });
    },
    async companyLogo(event) {
      const that = this;
      var file = event.target.files[0];
      var fileSize = file.size; //文件大小
      var filetType = file.type; //文件类型
      //创建文件读取对象
      // console.log(file);
      if (fileSize <= 10240 * 1024) {
        if (
            filetType == "image/png" ||
            filetType == "image/jpeg" ||
            filetType == "image/gif"
        ) {
          var file_re = await this.readFileAsBuffer(file);
          // console.log(this.imgFile);
          const res = await this.$api.aliyun_osssts()
          let client = new window.OSS.Wrapper({
            secure:true,
            region:res.region, //oss地址
            accessKeyId: res.accessKeyId, //ak
            accessKeySecret: res.accessKeySecret, //secret
            stsToken: res.stsToken,
            bucket:res.bucket, //oss名字
          });
          var imgtype = file.type.substr(6, 4);
          var store = `${new Date().getTime()}.${imgtype}`;
          console.log(store);
          client.put(store, file_re).then(() => {
            //这个结果就是url
            var a = client.signatureUrl(store);
            if (a.match('^http://')) {
              a = a.replace("http://", "https://")
            }
            this.oss_imgurl = a;
            console.log(a);
            if(this.imgStatus == 'tb'){
              this.$set(this.addForm,'pic',a)
            }
          });
          that.$refs.fileInputList.value = "";
        } else {
          this.$message.error("图片格式不正确");
        }
      } else {
        this.$message.error("图片大小不正确");
      }
    },
    // 添加搜索处理方法
    handleSearch() {
      this.currentPage = 1; // 搜索时重置到第一页
    }
  }
};
</script>

<style lang="scss" scoped>
.index {}

.nav1 {
  margin: 0 -18px;
  background-color: #fff;
  padding: 20px 32px 0 40px;

  .tit1 {
    color: #17233d;
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 20px;
  }
}

.myForm {
  ::v-deep  .el-form-item__label {
    font-size: 12px;
  }

  ::v-deep  .el-form-item {
    margin-right: 30px;
    margin-bottom: 0;
    vertical-align: middle;
  }
}

.tit1 {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.myTable {
  .xiala {
    padding: 10px 20px;

    .item {
      font-size: 12px;
      display: flex;
    }
  }

  margin-top: 10px;

  ::v-deep  .vxe-table--render-default .vxe-body--column {
    line-height: 14px;
    vertical-align: middle;
  }

  ::v-deep  .vxe-cell--label {
    font-size: 12px;
  }

  ::v-deep  .vxe-cell--title {
    font-size: 12px;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.nav2 {
  margin: 18px 0;
  background: #fff;
  border-radius: 6px;
  padding: 24px;

  .myForm {
    ::v-deep  .el-form-item__label {
      font-size: 12px;
    }

    ::v-deep  .el-form-item {
      margin-right: 30px;
      margin-bottom: 0;
      vertical-align: middle;
    }
  }

  .tit1 {
    margin-top: 10px;
  }

  .myTable {
    margin-top: 10px;

    ::v-deep  .vxe-table--render-default .vxe-body--column {
      line-height: 14px;
      vertical-align: middle;
    }

    ::v-deep  .vxe-cell--label {
      font-size: 12px;
    }

    ::v-deep  .vxe-cell--title {
      font-size: 12px;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.myAddForm {
  ::v-deep  .el-select {
    width: 100%;
  }

  ::v-deep  .el-form-item__label {
    font-size: 12px;
    width: 130px !important;
  }

  ::v-deep  .el-form-item__content {
    margin-left: 130px !important;
  }

  ::v-deep  .el-radio__label {
    font-size: 12px;
  }

  ::v-deep  .el-button {
    width: 100%;
  }

}

.fenye {
  margin-top: 16px;
}

.displayN {
  display: none;
}

.myImg {
  position: relative;
  width: 60px;
  height: 60px;
  display: inline-block;
  margin-right: 12px;

  .closeBtn {
    position: absolute;
    top: -6px;
    right: -4px;
    width: 20px;
    height: 20px;

    .el-button {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  ::v-deep  .image-slot {
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fafafa;
    width: 58px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .el-icon-picture-outline {
      font-size: 20px;
    }
  }

  .myImg {
    position: relative;
    width: 60px;
    height: 60px;
    display: inline-block;
    margin-right: 12px;

    .closeBtn {
      position: absolute;
      top: -6px;
      right: -4px;
      width: 20px;
      height: 20px;

      .el-button {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    ::v-deep  .image-slot {
      border: 1px solid #ddd;
      border-radius: 4px;
      background-color: #fafafa;
      width: 58px;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .el-icon-picture-outline {
        font-size: 20px;
      }
    }
  }
}
</style>
